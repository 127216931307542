import React from 'react';

const NotFoundPage = () => (
  <>
    <h1>404</h1>
    <p>Diese Seite existiert nicht.</p>
  </>
);

export default NotFoundPage;
